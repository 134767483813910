<template>
  <div>
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <!--begin::card-header-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('products.sync.log.item.title', { logId }) }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('products.sync.log.item.desc') }}
            </span>
          </h2>
        </b-card-title>
      </div>
      <!--end::card-header-->
      <!--begin::card-body-->
      <div v-if="loading" class="card-body">
        <Loader
          :text="trans('products.sync.log.item.loading')"
          loader-class="relative"
          loader-style="margin-right: 80px;"
        ></Loader>
      </div>
      <div v-else class="card-body">
        <div class="d-flex w-100">
          <!--begin::start-end-sync-->
          <div class="d-flex mr-10">
            <div class="mr-6">
              <div class="font-weight-bold mb-2">
                {{ trans('products.sync.started') }}
              </div>
              <span class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold">
                {{ momentFullDate(logEntry.createdOn) + '@' + momentHoursMinutesSeconds(logEntry.createdOn) }}
              </span>
            </div>
            <div>
              <div class="font-weight-bold mb-2">
                {{ trans('products.sync.ended') }}
              </div>
              <span class="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold">
                {{ momentFullDate(logEntry.endedOn) + '@' + momentHoursMinutesSeconds(logEntry.endedOn) }}
              </span>
            </div>
          </div>
          <!--end::start-end-sync-->
        </div>
        <div class="separator separator-solid my-7"></div>
        <!--begin::sync-details-->
        <div class="d-flex align-items-center flex-wrap">
          <!--begin::total-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.total') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ logEntry.data.customers.count }}
            </span>
            </div>
          </div>
          <!--end::total-->

          <!--begin::processed-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.processed') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ logEntry.data.customers.count }}
            </span>
            </div>
          </div>
          <!--end::processed-->

          <!--begin::unchanged-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-info">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.unchanged') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ logEntry.data.customers.unchanged }}
            </span>
            </div>
          </div>
          <!--end::unchanged-->

          <!--begin::new-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-success">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.new') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ logEntry.data.customers.new }}
            </span>
            </div>
          </div>
          <!--end::new-->

          <!--begin::updated-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-warning">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.updated') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ logEntry.data.customers.updated }}
            </span>
            </div>
          </div>
          <!--end::updated-->

          <!--begin::failed-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-danger">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.failed') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ logEntry.data.customers.failed }}
            </span>
            </div>
          </div>
          <!--end::failed-->
        </div>
        <!--end::sync-details-->
      </div>
      <!--end::card-body-->
    </b-card>

    <!--begin::items-list-->
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('products.sync.log.items.title') }}
            <span class="d-block text-muted pt-2 font-size-sm">
            {{ trans('products.sync.log.items.desc') }}
          </span>
          </h2>
        </b-card-title>
      </div>
      <!--begin::sync-list-->
      <div class="card-body" style="min-height: 80px;">
        <b-table
          id="syncItemsLog"
          primary-key="syncid"
          :fields="fields"
          :items="items"
          responsive="md"
        >
          <!--begin::status-->
          <template
            v-if="listProps.loadingItems"
            v-slot:cell(status)
            class="table-product"
          >
            <div class="list-item loading-rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(status)="data"
          >
            <span
              class="label label-lg font-weight-bold label-inline"
              :class="`label-light-${ data.item.status.variant }`"
            >
              {{ trans(`${ data.item.status.msg }.status`) }}
            </span>
          </template>
          <!--end::status-->

          <!--begin::main-->
          <template
            v-if="listProps.loadingItems"
            v-slot:cell(main)
            class="table-product"
          >
            <div class="list-item loading-rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(main)="data"
          >
            <div class="text-dark font-weight-bolder font-size-lg mb-0">
              {{ data.item.main.title }}
            </div>
            <div class="text-muted">
              {{ truncate(data.item.main.desc, 60, '..') }}
            </div>
          </template>
          <!--end::main-->
        </b-table>

        <!--begin::more-btn-->
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="d-flex align-items-center py-3">
            <div class="d-flex align-items-center">
              <Loader
                v-if="listProps.loadingItems"
                :text="trans('products.sync.log.items.btn.loadMore')"
                loader-class="relative"
                loader-style="margin-right: 80px;"
              ></Loader>
              <b-btn
                v-else-if="listProps.showMoreBtn"
                ref="logitems_showmorebtn"
                variant="primary"
                class="py-4 px-6"
                size="sm"
                @click="loadMore(listProps.lastVisibleDoc)"
              >
                {{ trans('products.sync.log.items.btn.loadMore') }}
              </b-btn>
            </div>
          </div>
        </div>
        <!--end::more-btn-->
      </div>
      <!--end::sync-list-->
    </b-card>
    <!--end::items-list-->
  </div>
</template>

<script>
  import * as objectPath from 'object-path'
  import { ref, computed, onBeforeMount, onMounted } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import useDateTime from '@src/use/datetime'
  import { capitalize, truncate } from '@src/core/helpers/textUtils'
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  export default {
    name: 'ProductsSyncLogItem',
    components: { Loader: () => import('@src/components/content/Loader') },
    setup (props, { root, refs }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const logId = ref(root.$route.params.logId)
      const logEntry = ref({})

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('logs.index.title'),
            route: '/logs'
          },
          { title: capitalize(transChoice('global.customer', 2)) },
          { title: trans('logs.customers.sync.title') }
        ])
      })

      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      const listProps = ref({
        limit: 20,
        loading: true,
        loadingItems: true,
        orderBy: 'pos',
        sortOrder: 'asc',
        lastVisibleDoc: true,
        showMoreBtn: true,
        type: 'customersync'
      })

      const logEntryItems = ref(new Array(listProps.value.limit).fill({ })) // Create placeholders while loading
      const loading = computed(() => !Object.keys(logEntry.value).length > 0)
      const loadingItems = computed(() => !logEntryItems.value.length >= 0 && Object.keys(logEntryItems.value[0]).length > 0) // eslint-disable-line max-len

      const fetchLogItem = async () => {
        const getLogEntry = fb.functions.httpsCallable('backendLogsGetLogById')

        await getLogEntry({
          id: logId.value,
          data: {
            limit: listProps.value.limit
          }
        }).then((res) => {
          listProps.value.loading = false
          logEntry.value = objectPath.get(res, 'data.result')
        }).catch((err) => {
          console.log('Error while fetching logs:', err)
        })

        return true
      }

      const loadMore = async (paginateDoc) => {
        const btn = refs.logitems_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getLogItems = fb.functions.httpsCallable('backendLogsGetLogItems')

        return await getLogItems({
          data: {
            logId: logId.value,
            limit: listProps.value.limit,
            orderBy: listProps.value.orderBy, // disabled in backend
            sort: listProps.value.sortOrder,
            paginateDoc
          }
        }).then((res) => {
          if (listProps.value.loadingItems) logEntryItems.value = []
          listProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= listProps.value.limit // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            logEntryItems.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          listProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      fetchLogItem()
      loadMore(true).then(() => {
        listProps.value.loadingItems = false
      })

      const itemName = (item) => {
        let name = objectPath.get(item, 'data.customer.name', '') // Product names
        if (name === '') { // Fallback to language keys
          name = objectPath.get(item, 'langKeys', '')
          if (name !== '') name = trans(`${ name }.title`)
          else name = trans('products.sync.log.missingTitle')
        }

        return name
      }

      const itemExtId = (item) => {
        let extId = objectPath.get(item, 'data.customer.extId', '')
        if (
          extId === '' &&
          objectPath.has(item, 'data.langKeys') &&
          objectPath.get(item, 'data.langKeys' !== 'customers.sync.item.start') &&
          objectPath.get(item, 'data.langKeys' !== 'customers.sync.item.end')
        ) {
          extId = trans('products.sync.log.missingExtId')
        }

        return extId
      }

      const itemId = (item) => {
        let id = objectPath.get(item, 'data.customer.id', '')
        if (
          id === '' &&
          objectPath.get(item, 'data.langKeys' !== 'customers.sync.item.start') &&
          objectPath.get(item, 'data.langKeys' !== 'customers.sync.item.end')
        ) {
          id = trans('products.sync.log.missingExtId')
        }

        return id
      }

      const items = computed(() => logEntryItems.value.map((logSubItem) => {
        if (!listProps.value.loadingItems) {
          return {
            status: {
              variant: objectPath.get(logSubItem, 'variant', 'dark'),
              msg: objectPath.get(logSubItem, 'langKeys', 'products.sync.log.missingStatus')
            },
            name: itemName(logSubItem),
            extId: itemExtId(logSubItem),
            id: itemId(logSubItem)
          }
        }

        return {} // ...else return nothing
      }))

      const fields = computed(() => [
        {
          key: 'status',
          label: capitalize(transChoice('global.status', 0)),
          sortable: false,
          tdClass: 'log-main'
        },
        {
          key: 'name',
          label: capitalize(transChoice('global.name', 0)),
          sortable: false,
          tdClass: 'log-main'
        },
        {
          key: 'extId',
          label: capitalize(transChoice('global.id', 0)),
          sortable: false,
          tdClass: 'log-id'
        },
        {
          key: 'id',
          label: capitalize(transChoice('global.id', 0)),
          sortable: false,
          tdClass: 'log-id'
        }
      ])

      return {
        logId,
        logEntry,
        trans,
        transChoice,
        loading,
        loadingItems,
        momentFullDate,
        momentHoursMinutesSeconds,
        // List
        listProps,
        logEntryItems,
        loadMore,
        fields,
        items,
        truncate
      }
    }
  }
</script>

<style lang="scss">

</style>
